* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	font-family: $roboto;
	font-size: 10px;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

.sr-only {
	display: none;
}
