.menu {
	@include flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	background: $primary;
	visibility: hidden;
	clip-path: circle(0vh at 90% 55px);
	transition: 0.4s ease;
	&.open {
		visibility: visible;
		clip-path: circle(150% at 90% 55px);
	}
	@include mq(md) {
		position: static;
		height: auto;
		visibility: visible;
		clip-path: none !important;
		background: transparent;
		overflow-y: visible;
		width: auto;
	}
	ul {
		list-style: none;
		height: 100%;
		@include flex;
		flex-direction: column;

		@include mq(md) {
			flex-direction: row;
		}
		li {
			padding: 2rem 0;
			@include mq(md) {
				padding: 0 1rem;
			}
			@include mq(lg) {
				padding: 0 2rem;
			}
			&:last-child {
				padding: 0;
			}
			a {
				text-decoration: none;
				color: #fff;
				font-size: 2rem;
				transition: 0.4s ease;
				@include mq(md) {
					text-transform: none;
					font-size: 1.4rem;
					color: $secondary;
					&:hover {
						color: $primary;
					}
				}
				@include mq(lg) {
					font-size: 1.6rem;
				}

				&.button {
					display: none;
					@include mq(md) {
						display: block;
						color: #fff;
					}
				}
			}
		}
	}
}
