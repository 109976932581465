.topbar {
	display: none;
	@include mq(md) {
		display: block;
		border-bottom: 1px solid #ededed;
		background-color: #fff;
		position: relative;
		z-index: 101;
		&__container {
			display: flex;
			@include mq(md) {
				justify-content: center;
			}
			@include mq(lg) {
				justify-content: space-between;
			}
		}

		&__contact {
			display: flex;
			&-single {
				padding: 1.5rem 2.5rem;
				&:first-child {
					padding-left: 0;
				}
				font-size: 1.5rem;
				font-weight: bold;
				color: $secondary;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				text-decoration: none;
				img {
					margin-right: 10px;
				}
			}
		}
		&__socials {
			display: none;
			@include mq(lg) {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
