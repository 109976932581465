.hero {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column-reverse;
	padding-bottom: 80px;
	@include mq(md) {
		min-height: 700px;
		flex-direction: row;
	}
	&__inner {
		display: flex;
		flex-direction: column;
		@include mq(md) {
			align-items: center;
			flex-direction: row;
		}
	}
	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include mq(md) {
			border-radius: 10px;
			background-color: #fff;
			box-shadow: $shadow;
			position: relative;
			z-index: 3;
			padding: 50px;
			align-items: flex-start;
			width: 55%;
		}
	}
	&__image {
		width: calc(100% - 2rem);
		background-size: cover;
		background-position: center;
		overflow: hidden;
		position: relative;
		box-shadow: $shadow;
		margin: 2rem;
		border-radius: 25px;
		@include mq(md) {
			height: 100%;
			border-radius: 0;
			width: 50%;
			position: absolute;
			margin: 0;
			top: 0;
			right: 0;
		}
		img {
			min-height: 200px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__title {
		text-align: center;
		font-size: 4rem;
		margin-bottom: 15px;
		@include mq(md) {
			text-align: left;
			font-size: 5rem;
		}

		font-weight: 900;
		text-transform: uppercase;
		color: $secondary;
	}
	&__desc {
		text-align: center;
		font-size: 1.8rem;
		line-height: 30px;
		@include mq(md) {
			text-align: left;
		}
		color: $secondary;
	}
	.button {
		padding: 1.2rem 6rem;
		font-size: 1.9rem;
		margin: 35px 0;
		@media (prefers-reduced-motion: no-preference) {
			animation: pulse 2s infinite;
		}
	}
}
.custom-shape-divider-bottom-1614038737 {
	position: absolute;
	bottom: -2px;
	z-index: 10;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
}

.custom-shape-divider-bottom-1614038737 svg {
	position: relative;
	display: block;
	width: calc(158% + 1.3px);
	height: 79px;
}

.custom-shape-divider-bottom-1614038737 .shape-fill {
	fill: #f4f4f4;
}

.home .custom-shape-divider-bottom-1614038737 .shape-fill {
	fill: $primary;
}
