.header {
	padding: 1.5rem 0;
	box-shadow: $shadow;
	position: sticky;
	z-index: 100;
	top: 0px;
	background-color: #fff;
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
