$hamburgerHeight: 8;

.hamburger {
	background-color: $primary;
	position: relative;
	padding: 18px 15px;
	transition: 0.2s ease;
	border: none;
	box-shadow: $shadow;
	cursor: pointer;
	top: 0px;
	z-index: 999;
	border-radius: 100%;
	display: flex;
	align-items: center;
	@include mq(md) {
		display: none;
	}
}

.hamburger__container {
	width: 25px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
	cursor: pointer;
	display: block;
	height: 18px;
}

.hamburger__item {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	background: #fff;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
	border-radius: 1px;
}

.hamburger__item:nth-child(1) {
	top: 0px;
}

.hamburger__item:nth-child(2) {
	top: #{$hamburgerHeight}px;
}

.hamburger__item:nth-child(3) {
	top: #{$hamburgerHeight * 2}px;
}

.hamburger--open .hamburger__item:nth-child(1) {
	transform: rotate(45deg);
	top: #{$hamburgerHeight}px;
	left: 0px;
}

.hamburger--open .hamburger__item:nth-child(2) {
	width: 0%;
	opacity: 0;
}

.hamburger--open .hamburger__item:nth-child(3) {
	transform: rotate(-45deg);
	top: #{$hamburgerHeight}px;
	left: 0px;
}

.hamburger--open .hamburger__item {
	background: #fff;
}
