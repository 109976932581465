.container {
	margin-left: auto;
	max-width: $containerWidth;
	width: 100%;
	margin-right: auto;
	padding: 0 2rem;
	@include mq(xl) {
		padding: 0;
	}
	&--flex {
		@include flexContainer;
	}
	&--center {
		@include flexContainer;
	}
	&--between {
		@include flexContainer(false);
		@include mq(md) {
			justify-content: space-between;
		}
	}
}
